import form from './form.json';
import newsletterForm from './newsletterForm.json';
import recaptchaLegal from './recaptchaLegal.json';

export default {
  translation: {
    form,
    newsletterForm,
    recaptchaLegal,
  },
};
