export const getLanguagesList = () => {
  const languageLists = process.env.GATSBY_LANGUAGE_CODENAMES?.split(',').filter(Boolean);
  return languageLists?.length ? languageLists : ['en'];
};

export const getPathWithLanguage = (path: string, language: string) => {
  const languageToAppend = language !== 'en' ? `/${language}` : '';

  if (getLanguageFromPath(path) === language) {
    return path;
  }

  return `${languageToAppend}${path}`;
};

export const getLanguageFromPath = (pathname: string) =>
  getLanguagesList().find((lang) => pathname.startsWith(`/${lang}/`)) ?? 'en';
